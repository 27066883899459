import { registerLinkHandler } from '@/sections/sign';
import { assignListenersToTrLinks } from '@/components/accessTraderoom';

function renderWordpressBanners() {
  const cpBanner1 = document.querySelector('.js-wp-cp-banner-1');
  const orgBanner1 = document.querySelector('.js-wp-org-banner-1');

  if (cpBanner1) {
    const title = document.querySelector('h1').innerText;
    const bannerTitle = document.querySelector('.js-banner-title');
    fetch('/app/banners/process/?type=chart-patterns', {
      credentials: 'include',
    })
      .then((res) => res.text())
      .then((res) => {
        cpBanner1.innerHTML = res;
      })
      .finally(() => {
        if (bannerTitle && title) {
          bannerTitle.innerText = title.toLowerCase();
        }
        document.querySelectorAll('.js-register-link').forEach((btn) => {
          btn.addEventListener('click', (event) => {
            event.preventDefault();
            registerLinkHandler();
          });
        });
        assignListenersToTrLinks();
      });
  }

  if (orgBanner1) {
    fetch('/app/banners/process/?type=organic', {
      credentials: 'include',
    })
      .then((res) => res.text())
      .then((res) => {
        orgBanner1.innerHTML = res;
      })
      .finally(() => {
        document.querySelectorAll('.js-register-link').forEach((btn) =>
          btn.addEventListener('click', (event) => {
            event.preventDefault();
            registerLinkHandler();
          })
        );
        assignListenersToTrLinks();
      });
  }
}

renderWordpressBanners();
