// import code for every page

import '@/global';
import '@/components/sw';
import '@/components/exitPopup';
import '@/components/emailGrabber';
import '@/components/bannerRendering';

// import code by section presence
// section should have data-js-import="name" attribute

const sections = [
  'header-navigation',
  'footer',
  'sign',
  'promo-ribbon',
  'wp-sidebar-banner',
];

sections
  .filter((name) => document.querySelector(`section[data-js-import="${name}"]`))
  .forEach((name) => {
    import(
      // /* webpackChunkName: "forexvpsChunk" */
      `@/sections/${name}`
    );
  });
